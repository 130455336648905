import Empty from "@/components/Empty";
import Loader from "@/components/Loader";
import { NoticeTypeEnumMaps } from "@/enums";
import noticeModel from "@/models/notice.model";
import { useInfiniteScroll, useReactive } from "ahooks";
import dayjs from "dayjs";
import KeepAlive from "keepalive-for-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Image,
  List,
  PullRefresh,
  Search,
  Sticky,
  Tabs,
  Tag,
} from "react-vant";
import styles from "./index.module.css";

interface ListViewProps {
  type: string;
  keyword?: string;
  onClick?: (item: any) => void;
}

const ListView = ({ type, keyword, onClick }: ListViewProps) => {
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return noticeModel
          .list({
            type,
            params: {
              keyword,
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [type, keyword],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item: any) => (
            <div
              key={item.id}
              className={styles.item}
              onClick={() => onClick && onClick(item)}
            >
              <div className={styles.left}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.footer}>
                  <div className={styles.date}>
                    <Image
                      className={styles.icon}
                      src={require("@/assets/icons/date.png").default}
                    />
                    {dayjs(item.publishAt).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                  <Tag plain round type="primary" size="medium">
                    {NoticeTypeEnumMaps[item.type]}
                  </Tag>
                </div>
              </div>
              <div className={styles.right}>
                <Image
                  className={styles.image}
                  src={`/images/notice/${item.type}.jpg`}
                />
              </div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  const nav = useNavigate();
  const state = useReactive({
    tab: "all",
    keyword: "",
  });

  const tabs = useMemo(() => {
    return [
      {
        key: "all",
        title: "全部",
        component: ListView,
        cache: true,
      },
      ...Object.keys(NoticeTypeEnumMaps).map((key) => {
        return {
          key,
          title: NoticeTypeEnumMaps[key],
          component: ListView,
          cache: true,
        };
      }),
    ];
  }, []);

  const page = useMemo(() => {
    return tabs.find((tab) => tab.key === state.tab);
  }, [tabs, state.tab]);

  return (
    <div className={styles.page}>
      <Sticky>
        <Search
          className={styles.search}
          placeholder="搜索历史公告"
          clearable
          leftIcon={
            <Image
              className={styles.icon}
              src={require("@/assets/icons/search.png").default}
            />
          }
          value={state.keyword}
          onSearch={(e) => {
            state.keyword = e;
          }}
        />
        <Tabs
          className={styles.tabs}
          type="capsule"
          align="start"
          active={state.tab}
          onChange={(key) => {
            state.tab = key.toString();
          }}
        >
          {tabs.map((item) => (
            <Tabs.TabPane key={item.key} name={item.key} title={item.title} />
          ))}
        </Tabs>
      </Sticky>
      <KeepAlive
        max={20}
        strategy={"PRE"}
        activeName={state.tab + state.keyword}
        cache={page?.cache}
      >
        {page && (
          <page.component
            type={page.key}
            keyword={state.keyword}
            onClick={(item) => {
              nav(`/notice/detail/${item.id}`);
            }}
          />
        )}
      </KeepAlive>
    </div>
  );
};
